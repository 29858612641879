import { Link } from "@appiodev/xcore-client/xcore-ui";
import { Box, Button, Flex, Modal, Typography } from "@xcorejs/ui";
import { FC } from "react";
import { useLayout } from "xcore";
import { Innovation } from "xcore/types";
import ImagesCarousel from "./ImagesCarousel";
import VideoCarousel from "./VideoCarousel";

interface Props {
  innovation: Innovation;
}

const InnovationModal: FC<Props> = ({ innovation: { values, ...content } }) => {
  const { stringify, general, value, file } = useLayout();

  return (
    <Modal size="lg" _overlay={{ zIndex: 5 }}>
      <Typography variant="h3" as="div" textAlign="center">
        {stringify(values.name)}
      </Typography>

      <Typography variant="p" textAlign="center" mt="2rem">
        {stringify(values.intro)}
        <Link content={content}>{stringify(general.values.readMore)}</Link>
      </Typography>

      {value(values.video) && (
        <Box width="74rem" maxWidth="100%" margin="auto" mb={{ _: "4rem", sm: "8rem" }}>
          <Flex>
            <VideoCarousel
              videos={value(values.video) ?? []}
              loop={value(values.loop)}
              autoplay={value(values.autoplay)}
              openInModal={value(values.openInModal)}
            />
          </Flex>
        </Box>
      )}

      {value(values.images) && (
        <Box mb={{ _: "2rem", sm: "8rem" }}>
          <Box>
            <ImagesCarousel
              fitToPageContent
              disableScroll
              hideSubHeader
              images={value(values.images)!.map(img => [
                file(img, value(values.images)!.length > 1 ? { width: 740, height: 416 } : { width: 1290, height: 726 }),
                value(img.name),
                file(img, value(values.images)!.length > 1
                  ? { width: 740, height: 416, enlargement: 2 }
                  : { width: 1290, height: 726, enlargement: 2 }
                )
              ])}
            />
          </Box>
        </Box>
      )}
      <Flex justifyContent="center" mt={{ _: "2rem", sm: "3rem" }}>
        <Link content={content}>
          <Button>
            {stringify(general.values.btnExploreMore)}
          </Button>
        </Link>
      </Flex>
    </Modal>
  );
};

export default InnovationModal;
